.carouselWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 20pc;
}

.separatingLine{
    height: 1px;
    width: 15pc;
    border-top: 1px solid var(--basicGrey);
    margin: 0 auto;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
   line-height: 56px;
   letter-spacing: -2px;
   text-align: left;

   @media(max-width: 768px){
   font-size: 23px;
   letter-spacing: -1px;
   line-height: 25px;
   }
   }

   .listingWrapper{
     padding-right: 10px;
   }
   
   .searchButton{
     @media (min-width: 768px){
       width: 20pc;
       margin: 0 auto;
     }

     @media (max-width: 768px){
      margin: 0 auto;
    }
    margin-top: 3pc;

   }