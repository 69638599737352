@import '../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
  min-height: 35pc;


  @media (max-width: 1405px){
    min-height: 32pc;
  }

  @media (max-width: 1105px){
    min-height: 30pc;
  }

  @media (max-width: 768px){
    min-height: 45pc;
  }

  @media (max-width: 600px){
    min-height: 40pc;
  }

  @media (max-width: 530px){
    min-height: 36pc;
  }

  @media (max-width: 430px){
    min-height: 30pc;
  }
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: 0 4pc;
  @media (max-width: 768px){
  margin: 0 1pc;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}



/* guides section */

.guidePic{
  height: 16pc;
  width: 15pc;
  object-fit: cover;
}
.guideSlide{
  display: flex;
  flex-direction: column;
}
.guideText{
  text-align: center;
  font-size: 20px;
}


.buttonsWrapper{
  display: flex;
  margin: 0 4pc;
  justify-content: space-between;
  margin-bottom: 3pc;
  @media (max-width: 768px){
    margin: 0 1pc;
    flex-direction: column;
  }
}

.buttonsSubsection{
  display: flex;
  width: 50%;
  justify-content: space-between;

  @media (max-width: 768px){
    width: 100%;
    margin-bottom: 1pc;
    justify-content: space-between;

  }
}

.homepageButton{
  margin-right: 1pc;
  background-color: var(--marketplaceColor);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width: 50%;
  @media (max-width: 768px){
    width: 48%;
    margin-right: 0;
  }

  &:hover{
    transform: scale(1.05);
  }
}


.title {
  font-size: 32px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -2px;
  text-align: left;
  margin-left: 4pc;
 @media(max-width: 768px){
   margin-left: 1pc;
   font-size: 23px;
   letter-spacing: -1px;
 }
}